import { flostaApiClientBase } from "./flostaApiClient.base";
import { TFlower } from "../../context/flower/flower.type";
import { TEvent, TEventPlan } from "../../context/event/event.type";
import {
  ApiEvent,
  TGetBannersResponse,
  TGetCompanyUsersResponse,
  TGetEventOrdersResponse,
  TGetEventPlansResponse,
  TGetEventsResponse,
  TGetFlowersResponse,
  TGetOrdersResponse,
  TGetPrivateUsersResponse,
} from "./flostaApiClient.type";
import { TCompanyUser } from "../../context/user/companyUser.type";
import { TPrivateUser } from "../../context/user/privateUser.type";
import { TEventOrder, TFlowerOrder } from "../../context/order/order.type";
import { TBanner } from "../../context/banner/banner.type";

export const flostaApiClientGet = {
  flowers: async (idToken: string): Promise<Array<TFlower>> =>
    (await flostaApiClientBase.get<TGetFlowersResponse>("/flowers", idToken))
      .flowers,
  events: async (idToken: string): Promise<Array<TEvent>> => {
    const response = await flostaApiClientBase.get<TGetEventsResponse>(
      "/events",
      idToken
    );
    return response.events.map(ApiEvent.toContext);
  },
  plans: async (idToken: string): Promise<Array<TEventPlan>> =>
    (await flostaApiClientBase.get<TGetEventPlansResponse>("/plans", idToken))
      .plans,
  banners: async (idToken: string): Promise<Array<TBanner>> =>
    (await flostaApiClientBase.get<TGetBannersResponse>("/banners", idToken))
      .banners,
  companyUsers: async (idToken: string): Promise<Array<TCompanyUser>> =>
    (
      await flostaApiClientBase.get<TGetCompanyUsersResponse>(
        "/company_users",
        idToken
      )
    ).companyUsers as Array<TCompanyUser>,
  privateUsers: async (idToken: string): Promise<Array<TPrivateUser>> =>
    (
      await flostaApiClientBase.get<TGetPrivateUsersResponse>(
        "/private_users",
        idToken
      )
    ).privateUsers as Array<TPrivateUser>,
  flowerOrders: async (idToken: string): Promise<Array<TFlowerOrder>> =>
    (
      await flostaApiClientBase.get<TGetOrdersResponse>(
        "/flower_orders",
        idToken
      )
    ).flowerOrders,
  eventOrders: async (idToken: string): Promise<Array<TEventOrder>> =>
    (
      await flostaApiClientBase.get<TGetEventOrdersResponse>(
        "/event_orders",
        idToken
      )
    ).eventOrders,
};
