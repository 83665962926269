const url = process.env.REACT_APP_FLOSTA_API_URL;

const base = async <T>(
  path: string,
  method: string,
  body?: string | FormData,
  idToken?: string
): Promise<T> => {
  try {
    let headers = {};
    if (idToken)
      headers = Object.assign({}, headers, { "x-auth-key": idToken });
    if (typeof body === "string")
      headers = Object.assign({}, headers, {
        "Content-Type": "application/json",
      });
    const response = await fetch(url + path, { method, headers, body });
    if (response.ok) return (await response.json()) as T;
    throw new Error((await response.json()).message);
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const flostaApiClientBase = {
  get: async <T>(path: string, idToken?: string): Promise<T> =>
    await base(path, "GET", undefined, idToken),
  post: async <T>(
    path: string,
    body: string | FormData,
    idToken?: string
  ): Promise<T> => await base(path, "POST", body, idToken),
  patch: async <T>(path: string, body: string, idToken?: string): Promise<T> =>
    await base(path, "PATCH", body, idToken),
  delete: async <T>(path: string, idToken?: string): Promise<T> =>
    await base(path, "DELETE", undefined, idToken),
};
