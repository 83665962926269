import { TEventPlan } from "../../../context/event/event.type";
import { Button, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";
import { Table } from "antd";
import NoImage from "../../../image/no_image.png";
import { TEntityImage } from "../../../context/flower/flower.type";
import { NavLink } from "react-router-dom";

export const eventPlansTableColumns = () => [
  {
    title: "",
    dataIndex: "imageUrl",
    render: (imageUrl: TEntityImage) => (
      <img
        src={imageUrl.url ? imageUrl.url : NoImage}
        style={{ width: "80px", height: "80px" }}
        alt={"event-plan-image-" + (imageUrl ? imageUrl.id : "no-image")}
      />
    ),
  },
  {
    title: "プラン名",
    dataIndex: "name",
    render: (_, record) => <Typography>{record.name}</Typography>,
  },
  {
    title: "金額",
    dataIndex: "price",
    render: (_, record) => (
      <Typography>{record.price.toLocaleString()}円</Typography>
    ),
  },
  {
    title: "",
    width: 100,
    render: (_, record) => (
      <NavLink to={"/plans/" + record.id}>
        <Button>
          <EditIcon />
        </Button>
      </NavLink>
    ),
  },
];

type EventPlanTableProps = {
  eventPlans: Array<TEventPlan>;
};

const EventPlanTabel: React.FC<EventPlanTableProps> = ({ eventPlans }) => {
  const eventPlanTableData = [];

  eventPlans?.forEach((plan) => {
    eventPlanTableData.push({ key: plan.id, ...plan });
  });

  return (
    <div style={{ margin: "0px 40px" }}>
      <Table
        columns={eventPlansTableColumns()}
        dataSource={eventPlanTableData}
      />
    </div>
  );
};

export default EventPlanTabel;
