import { flostaApiClientBase } from "./flostaApiClient.base";

export const flostaApiClientDelete = {
  flower: async (idToken: string, flowerId: string): Promise<void> =>
    await flostaApiClientBase.delete(`/flowers/${flowerId}`, idToken),
  selectableOption: async (idToken: string, optionId: string): Promise<void> =>
    await flostaApiClientBase.delete(
      `/selectable_options/${optionId}`,
      idToken
    ),
  choice: async (idToken: string, choiceId: string): Promise<void> =>
    await flostaApiClientBase.delete(`/choices/${choiceId}`, idToken),
  textOption: async (idToken: string, textOptionId: string): Promise<void> =>
    await flostaApiClientBase.delete(`/text_options/${textOptionId}`, idToken),
  event: async (eventId: string, idToken: string): Promise<void> =>
    await flostaApiClientBase.delete(`/events/${eventId}`, idToken),
  plan: async (planId: string, idToken: string): Promise<void> =>
    await flostaApiClientBase.delete(`/plans/${planId}`, idToken),
  banner: async (bannerId: string, idToken: string): Promise<void> =>
    await flostaApiClientBase.delete(`/banners/${bannerId}`, idToken),
  flowerImage: async (flowerImageId: string, idToken: string): Promise<void> =>
    await flostaApiClientBase.delete(
      `/flower_images/${flowerImageId}`,
      idToken
    ),
  eventImage: async (eventImageId: string, idToken: string): Promise<void> =>
    await flostaApiClientBase.delete(`/event_images/${eventImageId}`, idToken),
  eventPlanImage: async (
    eventPlanImageId: string,
    idToken: string
  ): Promise<void> =>
    await flostaApiClientBase.delete(
      `/plan_images/${eventPlanImageId}`,
      idToken
    ),
  bannerImage: async (bannerImageId: string, idToken: string): Promise<void> =>
    await flostaApiClientBase.delete(
      `/banner_images/${bannerImageId}`,
      idToken
    ),
};
