import React from "react";
import {
  TFlowerOrderCompanyUser,
  TFlowerOrderPrivateUser,
  TFlowerOrder,
} from "../../context/order/order.type";
import { InfoWrapper, OrderDetailWrapper } from "./OrderDetail.style";
import OrderDetailLabel from "./OrderDetailLabel";
import { Chip, Typography } from "@material-ui/core";
import { convertDate } from "../../helper/ConvertDate";

type OrderDetailProps = {
  order: TFlowerOrder;
};

const FlowerOrderDetail: React.FC<OrderDetailProps> = ({ order }) => {
  return (
    <OrderDetailWrapper>
      <InfoWrapper>
        <Typography variant={"h6"}>注文情報</Typography>
        <OrderDetailLabel label="注文番号:" value={order.orderNo} />
        <OrderDetailLabel
          label="注文時刻:"
          value={convertDate(order.orderedAt)}
        />
      </InfoWrapper>
      <InfoWrapper>
        <Typography variant={"h6"}>注文者情報</Typography>
        <OrderDetailLabel
          label="名前:"
          value={
            order.orderUserType === "private"
              ? (order.orderUser as TFlowerOrderPrivateUser).name
              : (order.orderUser as TFlowerOrderCompanyUser).contactName
          }
        />
        <OrderDetailLabel
          label="メールアドレス:"
          value={order.orderUser.email}
        />
      </InfoWrapper>
      <InfoWrapper>
        <Typography variant={"h6"}>受取人</Typography>
        <OrderDetailLabel label="名前:" value={order.receiver.name} />
        <OrderDetailLabel
          label="電話番号:"
          value={order.receiver.phoneNumber}
        />
        <OrderDetailLabel label="郵便番号:" value={order.receiver.postalCode} />
        <OrderDetailLabel
          label="住所:"
          value={`${order.receiver.prefecture}${order.receiver.city}${order.receiver.address}${order.receiver.building}`}
        />
      </InfoWrapper>
      <InfoWrapper>
        <Typography variant={"h6"}>お支払い</Typography>
        <OrderDetailLabel
          label="小計:"
          value={order.payment.subtotal.toLocaleString() + "円"}
        />
        <OrderDetailLabel
          label="消費税:"
          value={order.payment.tax.toLocaleString() + "円"}
        />
        <OrderDetailLabel
          label="送料:"
          value={order.payment.postage.toLocaleString() + "円"}
        />
        <OrderDetailLabel
          label="合計:"
          value={order.payment.total.toLocaleString() + "円"}
        />
      </InfoWrapper>
      <InfoWrapper>
        <Typography variant={"h6"}>注文詳細</Typography>
        {order.orderItems.map((orderItem) => (
          <div>
            <OrderDetailLabel label="商品名:" value={orderItem.name} />
            <OrderDetailLabel
              label="単価:"
              value={orderItem.price.toLocaleString() + "円"}
            />
            <OrderDetailLabel
              label="数量:"
              value={orderItem.quantity.toString() + "個"}
            />
            <OrderDetailLabel
              label="価格:"
              value={orderItem.totalPrice.toLocaleString() + "円"}
            />
            <div style={{ marginTop: "30px", marginBottom: "30px" }}>
              <Typography>オプション:</Typography>
              {orderItem.orderedSelectableOptions?.map((selectableOption) => (
                <>
                  <Typography style={{ width: "100%", marginTop: "10px" }}>
                    {selectableOption.name + ":"}
                  </Typography>
                  {selectableOption.orderedChoices?.map((choice) => (
                    <Chip label={choice.name} />
                  ))}
                </>
              ))}
            </div>
            {orderItem.orderedTextOptions?.map((textOption) => (
              <OrderDetailLabel
                label={textOption.name + ":"}
                value={textOption.text}
              />
            ))}
          </div>
        ))}
      </InfoWrapper>
    </OrderDetailWrapper>
  );
};

export default FlowerOrderDetail;
