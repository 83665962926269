import { TEntityImage, TFlower } from "../../context/flower/flower.type";
import { TEvent, TEventPlan } from "../../context/event/event.type";
import { TEventOrder, TFlowerOrder } from "../../context/order/order.type";
import { TBanner } from "../../context/banner/banner.type";
import { TCompanyUser } from "../../context/user/companyUser.type";
import { TPrivateUser } from "../../context/user/privateUser.type";
import moment from "moment";

export type TGetFlowersResponse = {
  flowers: Array<TFlower>;
};

export type TGetEventsResponse = {
  events: Array<ApiEvent>;
};

export class ApiEvent {
  id: string;
  name: string;
  subName: string;
  description: string;
  imageUrl: TEntityImage | null;
  isDisplay: boolean;
  eventTags: Array<string>;
  date: string;
  place: string;

  static toContext(event: ApiEvent): TEvent {
    const date = moment(event.date);
    return {
      id: event.id,
      name: event.name,
      subName: event.subName,
      description: event.description,
      imageUrl: event.imageUrl,
      isDisplay: event.isDisplay,
      eventTags: event.eventTags,
      place: event.place,
      date,
    };
  }
}

export type TGetEventPlansResponse = {
  plans: Array<TEventPlan>;
};

export type TGetBannersResponse = {
  banners: Array<TBanner>;
};

export type TGetCompanyUsersResponse = {
  companyUsers: Array<TCompanyUser>;
};

export type TGetPrivateUsersResponse = {
  privateUsers: Array<TPrivateUser>;
};

export type TGetOrdersResponse = {
  flowerOrders: Array<TFlowerOrder>;
};

export type TGetEventOrdersResponse = {
  eventOrders: Array<TEventOrder>;
};

export type TCreateFlowerRequest = {
  name: string;
  description: string;
  price: number;
  isDisplay: boolean;
  isSoldOut: boolean;
  flowerTags: Array<string>;
};

export type TCreateEventRequest = {
  name: string;
  subName: string;
  description: string;
  isDisplay: boolean;
  eventTags: Array<string>;
  date: string;
  place: string;
};

export type TCreateEventPlanRequest = {
  eventId: string;
  name: string;
  price: number;
};

export type TCreateBannerRequest = {
  name: string;
  linkedPath?: string;
};

export type TCreateSelectableOption = {
  name: string;
  description: string;
  flowerId: string;
  minNum: number;
  maxNum: number;
  isRequired: boolean;
  isDisplay: boolean;
};

export type TCreateChoice = {
  name: string;
  price: number;
  isDefault: boolean;
  isSoldOut: boolean;
  isDisplay: boolean;
  selectableOptionId: string;
};

export type TCreateTextOption = {
  name: string;
  description: string;
  maxLength: number;
  isRequired: boolean;
  flowerId: string;
};

export type TUpdateEventRequest = {
  name: string;
  subName: string;
  date: string;
  place: string;
  isDisplay: boolean;
  eventTags: Array<string>;
  description: string;
};

export type TUpdateEventPlanRequest = {
  name: string;
  price: number;
};

export type TUpdateSelectableOption = {
  name: string;
  description: string;
  flowerId: string;
  minNum: number;
  maxNum: number;
  isRequired: boolean;
  isDisplay: boolean;
};

export type TUpdateChoice = {
  name: string;
  price: number;
  default: boolean;
  isSoldOut: boolean;
  isDisplay: boolean;
  selectableOptionId: string;
};

export type TUpdateTextOption = {
  name: string;
  description: string;
  maxLength: number;
  isRequired: boolean;
  flowerId: string;
};

export type TUpdateBannerRequest = {
  name: string;
  linkedPath?: string;
};
