import React, { useState } from "react";
import { TCompanyUser } from "../../context/user/companyUser.type";
import { EntityEditorWrapper } from "./EntityEditor.style";
import EntityEditorInput from "./components/EntityEditorInput";

type CompanyUserEditorProps = {
  companyUser: TCompanyUser;
  onCloseButtonClick: () => void;
};

const CompanyUserEditor: React.FC<CompanyUserEditorProps> = ({
  companyUser,
  onCloseButtonClick,
}) => {
  const [company, setCompany] = useState<string>(companyUser.profile.company);
  const [companyChar, setCompanyChar] = useState<string>(
    companyUser.profile.companyChar
  );
  const [departure, setDeparture] = useState<string>(
    companyUser.profile.department
  );
  const [contactName, setContactName] = useState<string>(
    companyUser.profile.contactName
  );
  const [contactNameChar, setContactNameChar] = useState<string>(
    companyUser.profile.contactNameChar
  );
  const [postalCode, setPostalCode] = useState<string>(
    companyUser.profile.postalCode
  );
  const [prefecture, setPrefecture] = useState<string>(
    companyUser.profile.prefecture
  );
  const [city, setCity] = useState<string>(companyUser.profile.city);
  const [address, setAddress] = useState<string>(companyUser.profile.address);
  const [tel, setTel] = useState<string>(companyUser.profile.phoneNumber);
  const [email, setEmail] = useState<string>(companyUser.profile.email);

  return companyUser ? (
    <EntityEditorWrapper>
      <EntityEditorInput
        label="企業名"
        value={company}
        onChange={setCompany}
        disabled={true}
      />
      <EntityEditorInput
        label="企業名（カナ）"
        value={companyChar}
        disabled={true}
        onChange={setCompanyChar}
      />
      <EntityEditorInput
        label="部署名"
        value={departure}
        disabled={true}
        onChange={setDeparture}
      />
      <EntityEditorInput
        label="担当者"
        value={contactName}
        disabled={true}
        onChange={setContactName}
      />
      <EntityEditorInput
        label="担当者（カナ）"
        value={contactNameChar}
        disabled={true}
        onChange={setContactNameChar}
      />
      <EntityEditorInput
        label="電話番号"
        value={tel}
        onChange={setTel}
        disabled={true}
      />
      <EntityEditorInput
        label="メールアドレス"
        value={email}
        disabled={true}
        onChange={setEmail}
      />
      <EntityEditorInput
        label="郵便番号"
        value={postalCode}
        disabled={true}
        onChange={setPostalCode}
      />
      <EntityEditorInput
        label="都道府県"
        value={prefecture}
        disabled={true}
        onChange={setPrefecture}
      />
      <EntityEditorInput
        label="市区町村"
        value={city}
        onChange={setCity}
        disabled={true}
      />
      <EntityEditorInput
        label="番地・マンション"
        value={address}
        disabled={true}
        onChange={setAddress}
      />
    </EntityEditorWrapper>
  ) : (
    <></>
  );
};

export default CompanyUserEditor;
