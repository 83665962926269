import { TEventOrder, TFlowerOrder } from "./order.type";
import React, { createContext, useContext, useState } from "react";
import { apiClient } from "../../infrastructure/apiClient";

type ContextProps = {
  orders: Array<TFlowerOrder>;
  eventOrders: Array<TEventOrder>;
  fetchOrders: () => void;
  fetchEventOrders: () => void;
};

const OrderContext = createContext({} as ContextProps);

const OrderProvider = ({ children }) => {
  const [orders, setOrders] = useState<Array<TFlowerOrder>>([]);
  const [eventOrders, setEventOrders] = useState<Array<TEventOrder>>([]);
  const fetchOrders = async () => {
    try {
      const idToken = await apiClient.auth.getIdToken();
      const orders = await apiClient.get.flowerOrders(idToken);
      setOrders(orders);
    } catch (e) {
      //TODO: Error Handling
      console.log(e);
    }
  };
  const fetchEventOrders = async () => {
    try {
      const idToken = await apiClient.auth.getIdToken();
      const eventOrders = await apiClient.get.eventOrders(idToken);
      setEventOrders(eventOrders);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <OrderContext.Provider
      value={{
        orders: orders,
        eventOrders,
        fetchOrders,
        fetchEventOrders,
      }}
    >
      <>{children}</>
    </OrderContext.Provider>
  );
};

export const useOrder = () => useContext(OrderContext);
export default OrderProvider;
